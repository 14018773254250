<template>
  <div>
    <v-card>
      <v-card-title>{{ $t('SearchOPDHistory') }}</v-card-title>
      <v-row class="px-2 mb-2">
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <v-select
            v-model="shop_id_pri"
            :placeholder="$t('select_branch')"
            :label="$t('select_branch')"
            :items="dataBranchList"
            item-text="shop_name"
            item-value="shop_id_pri"
            :no-data-text="$t('no_data')"
            outlined
            dense
            auto-select-first
            hide-details
          >
            <template v-slot:item="{ item }">
              {{ item.shop_name }}
              ({{ item.shop_id }})
            </template>
            <template v-slot:selection="{ item }">
              {{ item.shop_name }}
              ({{ item.shop_id }})
            </template>
          </v-select>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <v-autocomplete
            v-model.trim="customer_id_pri"
            :items="customerList"
            :label="$t('choose_a_checker')"
            :placeholder="$t('input_three_charector')"
            item-text="customer_fullname"
            outlined
            :filter="customFilter"
            return-object
            dense
            :search-input.sync="searchTextCustomer"
            hide-details
            :no-data-text="statusText"
            :loading="searchCustomerLoading"
            auto-select-first
            item-value="customer_id_pri"
          >
            <template v-slot:item="{ item }">
              {{ item.customer_fullname }}
              ({{ item.customer_id }})
            </template>
            <template v-slot:selection="{ item }">
              {{ item.customer_fullname }}
              ({{ item.customer_id }})
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <v-text-field
            v-model="opd_code"
            :placeholder="$t('opd_number')"
            :label="$t('checklist_no_opd')"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row
        v-if="customerData.customer_id_pri"
        align="center"
        class="px-2"
        justify="center"
      >
        <v-col
          cols="12"
          md="4"
          lg="3"
        >
          <div align="center">
            <v-avatar
              rounded
              size="120"
            >
              <v-img :src="customerData.customer_image"></v-img>
            </v-avatar>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="8"
          lg="9"
        >
          <v-row>
            <v-col
              cols="12"
              lg="4"
            >
              <span> {{ $t("code") }}: {{ customerData.customer_id }}</span>
            </v-col>
            <v-col
              cols="12"
              lg="4"
            >
              <span>{{ $t("id_card") }}: {{ customerData.customer_idcard }}</span>
            </v-col>
            <v-col
              cols="12"
              lg="4"
            >
              <span>{{ $t("firstname_lastname") }}: {{ customerData.customer_prefix }} {{ customerData.customer_fname }} {{ customerData.customer_lname }}</span>
            </v-col>
            <v-col
              cols="12"
              lg="4"
            >
              <span>{{ $t("gender") }}: {{ customerData.customer_gender }}</span>
            </v-col>
            <v-col
              cols="12"
              lg="4"
            >
              <span>{{ $t("be_allergic") }}: {{ customerData.customer_allergic }}</span>
            </v-col>
            <v-col
              cols="12"
              lg="4"
            >
              <span>{{ $t("birthday") }}: <span> <ConverseDate :date="customerData.customer_birthdate" /></span></span>
            </v-col>
            <v-col
              cols="12"
              lg="4"
            >
              <span>{{ $t("blood_type") }}: {{ customerData.customer_blood }}</span>
            </v-col>
            <v-col
              cols="12"
              lg="4"
            >
              <span>{{ $t("congenital_disease") }}: {{ customerData.customer_disease }}</span>
            </v-col>
            <v-col
              cols="12"
              lg="4"
            >
              <span>{{ $t("other") }}: {{ customerData.customer_comment }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="mt-5">
      <v-card-title class="px-2">
        <span class="me-2">{{ $t('opd_card_list') }}</span>
        <v-select
          v-model.trim="status_id"
          :items="statusList"
          :label="$t('select')"
          dense
          outlined
          hide-details
          :item-text="$i18n.locale"
          item-value="value"
          :no-data-text="$t('no_information')"
        ></v-select>
        <v-spacer class="d-none d-sm-block"></v-spacer>
        <div class="d-flex mt-2 mt-sm-0">
          <v-btn
            color="primary"
            class="me-2 d-none d-md-block"
            outlined
            :disabled="!selectedRows.length"
            @click="sendPrintLab(null)"
          >
            <v-icon
              small
              class="me-1"
            >
              {{ icons.mdiPrinter }}
            </v-icon> <span>{{ $t('print_lab') }}</span>
          </v-btn>
          <v-btn
            color="primary"
            class="me-2 d-block d-md-none"
            outlined
            fab
            small
            :disabled="!selectedRows.length"
          >
            <v-icon>
              {{ icons.mdiPrinter }}
            </v-icon>
          </v-btn>
          <v-btn
            color="info"
            class="me-2 d-none d-md-block"
            outlined
            :disabled="!selectedRows.length"
            @click="sendPrint(null)"
          >
            <v-icon
              small
              class="me-1"
            >
              {{ icons.mdiPrinter }}
            </v-icon><span> {{ $t('print_opd') }}</span>
          </v-btn>
          <v-btn
            color="info"
            class="me-2 d-block d-md-none"
            outlined
            fab
            small
            :disabled="!selectedRows.length"
            @click="sendPrint(null)"
          >
            <v-icon>
              {{ icons.mdiPrinter }}
            </v-icon>
          </v-btn>
          <v-btn
            color="warning"
            outlined
            class="d-none d-md-block"
            :disabled="!selectedRows.length"
            @click="printOpdImage"
          >
            <v-icon
              small
              class="me-1"
            >
              {{ icons.mdiPrinter }}
            </v-icon> <span>{{ $t('print_opd_image') }}</span>
          </v-btn>
          <v-btn
            color="warning"
            outlined
            fab
            small
            class="d-block d-md-none"
            :disabled="!selectedRows.length"
            @click="printOpdImage"
          >
            <v-icon>
              {{ icons.mdiPrinter }}
            </v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-data-table
        v-model.trim="selectedRows"
        :headers="tableColumns"
        :items="dataTableList"
        :footer-props="footer"
        :loading="loading"
        :options.sync="options"
        hide-default-footer
        disable-sort
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
        :single-select="singleSelect"
        show-select
        item-key="opd_id"
      >
        <template v-slot:[`item.opd_code`]="{ item }">
          <router-link
            v-if="item.check_link != 0"
            target="_blank"
            :to="{ name: 'ShowDetailCheck', params: { id: item.opd_id } }"
            class="font-weight-medium text-decoration-none"
          >
            {{ item.opd_code }}
          </router-link>
          <span v-else>
            {{ item.opd_code }}
          </span>
        </template>
        <template v-slot:[`item.opd_date`]="{ item }">
          <ConversDateTime :datetime="item.opd_date" />
        </template>
        <template v-slot:[`item.check`]="{ item }">
          <ol class="mb-0">
            <li
              v-for="(check, index) in item.check"
              :key="`${check.opdchecking_code} ${index}`"
            >
              {{ check.opdchecking_name }} ({{ check.opdchecking_code }})
            </li>
          </ol>
        </template>
        <template v-slot:[`item.course`]="{ item }">
          <ol class="mb-0">
            <li
              v-for="(course, index) in item.course"
              :key="`${course.orderdetail_name} ${index}`"
            >
              {{ course.orderdetail_name }} {{ course.orderdetail_amount }}
              {{ course.orderdetail_unit }}
            </li>
            <li
              v-for="(x, index) in item.drug"
              :key="`${index} ${x.orderdetail_name}`"
              class="primary--text"
            >
              {{ x.orderdetail_name }}
              {{ x.orderdetail_amount }}
              {{ x.orderdetail_unit }}
            </li>
          </ol>
        </template>
        <template v-slot:[`item.before`]="{ item }">
          <v-btn-toggle
            mandatory
            dense
            rounded
          >
            <v-btn
              color="error"
              small
              :disabled="!item.before"
              @click="previewImage(item.before, 1)"
            >
              <v-icon class="white--text me-1">
                {{ icons.mdiImageOutline }}
              </v-icon>
              {{ $t("before") }}
            </v-btn>
            <v-btn
              small
              color="primary"
              :disabled="!item.after"
              @click="previewImage(item.after, 2)"
            >
              <v-icon class="white--text me-1">
                {{ icons.mdiImageOutline }}
              </v-icon>
              {{ $t("after") }}
            </v-btn>
          </v-btn-toggle>
        </template>
        <template v-slot:[`item.face_chart`]="{ item }">
          <v-btn
            small
            :disabled="!item.face_chart"
            class="rounded-lg elevation-3"
            outlined
            color="primary"
            @click="previewImage(item.face_chart, 3)"
          >
            {{ $t("file") }}
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="showImage"
      max-width="600"
    >
      <v-card>
        <v-card-title>
          {{
            imageStatus == 1
              ? $t("picture_before")
              : imageStatus == 2
                ? $t("picture_after")
                : $t("document_important")
          }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="error"
            @click="showImage=false"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              v-for="(img, index) in urlImages"
              :key="index"
              cols="12"
              md="6"
              lg="4"
            >
              <v-img
                style="cursor:pointer"
                width="150"
                height="150"
                class="rounded-lg elevation-2"
                :src="img.opdupload_image"
                :lazy-src="img.opdupload_image"
                @click="showDetailimage(img.opdupload_image)"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <div align="center">
                <v-tooltip
                  color="#212121"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      class="mt-2 mr-2"
                      v-bind="attrs"
                      fab
                      x-small
                      v-on="on"
                      @click="downloadImage(img)"
                    >
                      <v-icon>
                        {{ icons.mdiCloudDownloadOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{
                    $t("download")
                  }}</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogVisible"
      max-width="500"
    >
      <v-img
        contain
        :src="dialogImageUrl"
        alt=""
      >
      </v-img>
    </v-dialog>
    <OpdOptions
      :id="idList"
      v-model="isPrintOpd"
    />
    <ResultLabelOptions
      :id="idList"
      v-model="isPrintResultLab"
    />
  </div>
</template>
<script>
import {
  mdiPrinter, mdiImageOutline, mdiClose, mdiCloudDownloadOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import useOPDCard from './useOPDCard'
import ConversDateTime from '@/components/basicComponents/ConversDateTime.vue'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import OpdOptions from '@/components/prints/OpdOptions.vue'
import ResultLabelOptions from '@/components/prints/ResultLabelOptions.vue'
import store from '@/store'

export default {
  components: {
    ConversDateTime,
    ConverseDate,
    ResultLabelOptions,
    OpdOptions,
  },
  setup() {
    const dialogVisible = ref(false)
    const showImage = ref(false)
    const urlImages = ref([])
    const s3Config = require('@/plugins/s3Confing')
    const s3 = s3Config()
    const imageStatus = ref('')
    const dialogImageUrl = ref('')
    const files = ref(null)
    const pathS3 = store.state.path_s3

    const showDetailimage = img => {
      dialogImageUrl.value = img
      dialogVisible.value = true
    }

    const previewImage = (url, status) => {
      urlImages.value = url
      imageStatus.value = status
      showImage.value = true
    }

    const downloadImage = async file => {
      files.value = {
        url: file.opdupload_image,
        name: file.opdupload_id,
      }
      const fileName = file.opdupload_image.replace(pathS3, '')
      const params = {
        Bucket: 'apsth-assets-upload',
        Key: fileName,
      }
      const data = await s3.getObject(params).promise()
      const blob = new Blob([data.Body])

      // Usage
      downloadBlob(blob, `${new Date()}.${files.value.type == 'pdf' ? 'pdf' : 'png'}`)
    }

    const downloadBlob = (blob, name) => {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { return window.navigator.msSaveOrOpenBlob(blob) }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = data
      link.download = name

      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        }),
      )

      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data)
        link.remove()
      }, 100)
    }

    return {
      urlImages,
      dialogImageUrl,
      imageStatus,
      dialogVisible,
      showImage,
      previewImage,
      showDetailimage,
      downloadImage,
      ...useOPDCard(),
      icons: {
        mdiPrinter,
        mdiCloudDownloadOutline,
        mdiImageOutline,
        mdiClose,
      },
    }
  },
}
</script>
