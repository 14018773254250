import opdCardTypes from '@/@fake-db/data/opdCardTypes.json'
import branch from '@/api/branch'
import customerOpd from '@/api/customer/customerOpd'
import customerSelection from '@/api/customerSelection'
import shop from '@/api/shop'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import router from '@/router'
import { ref, watch } from '@vue/composition-api'

export default function useOPDCard() {
  const statusText = ref(i18n.t('input_three_charector'))
  const dataTableList = ref([])
  const searchCustomerLoading = ref(false)
  const customerData = ref({})
  const searchTextCustomer = ref('')
  const customerList = ref([])
  const dataBranchList = ref([])
  const statusList = ref(opdCardTypes.data)
  const singleSelect = ref(false)
  const selectedRows = ref([])
  const isPrintResultLab = ref(false)
  const isPrintOpd = ref(false)
  const idList = ref([])
  const tableColumns = ref([
    {
      text: i18n.t('a_number'),
      align: 'start',
      value: 'opd_code',
      width: 120,
    },
    { text: i18n.t('date'), value: 'opd_date', width: 150 },
    { text: i18n.t('examination_list'), value: 'check', width: 200 },
    { text: i18n.t('list_treatment_use_services'), value: 'course', width: 500 },
    { text: i18n.t('doctor_inspector'), value: 'user_fullname', width: 250 },
    {
      text: i18n.t('photos_before_after'),
      value: 'before',
      width: 130,
      align: 'center',
    },
    {
      text: i18n.t('document_important'),
      value: 'face_chart',
      align: 'center',
      width: 250,
    },
  ])
  const customer_id_pri = ref({})
  const options = ref({
    itemsPerPage: -1,
  })
  const shop_id_pri = ref('')
  const thisShop = ref('')
  const status_id = ref('')
  const opd_code = ref('')
  const loading = ref(false)
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const { shop: getShop } = shop
  const { opdCardListByCustomer } = customerOpd

  // const { customerById } = customer
  const { branchList } = branch
  const { customerSelectionList } = customerSelection

  branchList().then(res => {
    dataBranchList.value = res
    getShop().then(res => {
      dataBranchList.value.unshift(res)
      thisShop.value = res.shop_id_pri
      shop_id_pri.value = res.shop_id_pri
    })
  })

  const fetchDataTable = () => {
    const body = {
      customer_id_pri: customer_id_pri.value.customer_id_pri,
      opd_code: opd_code.value,
      shop_id_pri: shop_id_pri.value,
      status_id: status_id.value,
    }
    opdCardListByCustomer(body).then(res => {
      dataTableList.value = res
      loading.value = false
    })
  }

  const dataCustomerSelectionList = textsearch => {
    const body = {
      searchtext: textsearch,
      shop_id_pri: shop_id_pri.value,
    }
    customerSelectionList(body).then(res => {
      customerList.value = res
      searchCustomerLoading.value = false
      statusText.value = !res.length ? i18n.t('no_data') : i18n.t('input_three_charector')
    })
  }

  const customFilter = (item, queryText) => {
    const textOne = item.customer_fullname.toLowerCase()
    const textThree = item.customer_id.toLowerCase()
    const textFour = item.customer_tel.toLowerCase()
    const searchText = queryText.toLowerCase()

    return textOne.indexOf(searchText) > -1 || textThree.indexOf(searchText) > -1 || textFour.indexOf(searchText) > -1
  }

  watch([shop_id_pri, searchTextCustomer, customer_id_pri, opd_code, status_id], (newvalue, oldvalue) => {
    if (newvalue[0] !== oldvalue[0]) {
      searchTextCustomer.value = ''
      customerData.value = {}
      dataTableList.value = []
      statusText.value = i18n.t('input_three_charector')
      customerList.value = []
    }
    if (newvalue[3] !== oldvalue[3]) {
      customerList.value = []
      searchTextCustomer.value = ''
      loading.value = true
      fetchDataTable()
    }
    if (newvalue[4] !== oldvalue[4]) {
      loading.value = true
      fetchDataTable()
    }
    if (newvalue[2] && newvalue[2] !== oldvalue[2]) {
      loading.value = true
      customerData.value = customer_id_pri.value ? customer_id_pri.value : {}
      fetchDataTable()
    } else {
      statusText.value = i18n.t('input_three_charector')
      customerList.value = []
    }
    if (newvalue[1] && newvalue[1].length >= 3) {
      statusText.value = i18n.t('data_loading')
      if (searchTextCustomer.value && searchTextCustomer.value.length >= 3) {
        dataCustomerSelectionList(searchTextCustomer.value)
      }
    } else {
      customerList.value = []
      statusText.value = i18n.t('input_three_charector')
    }
  })

  const printOpdData = () => {
    let arrOpd = []
    arrOpd = selectedRows.value.map(item => item.opd_id)
    const routeData = router.resolve({
      name: 'printOpdData',
      query: { id: String(arrOpd) },
    })
    window.open(routeData.href, '_blank')
  }
  const printOpdImage = () => {
    let arrOpd = []
    arrOpd = selectedRows.value.map(item => item.opd_id)
    const routeData = router.resolve({
      name: 'printOpdImage',
      query: {
        id: String(arrOpd),
        customerId: customer_id_pri.value.customer_id_pri,
      },
    })
    window.open(routeData.href, '_blank')
  }

  const sendPrint = id => {

    if (id) {
      idList.value = [id]
    } else {
      idList.value = selectedRows.value.map(item => item.opd_id)
    }

    if (localStorage.getItem('shop_id') && localStorage.getItem('shop_id') === '96') {
      const routeData = router.resolve({
        name: 'printOpdData1',
        query: {
          id: String(idList.value),
        },
      })
      window.open(routeData.href, '_blank')
    } else {
      isPrintOpd.value = true
    }

  }

  const sendPrintLab = id => {
    if (id) {
      idList.value = [id]
    } else {
      idList.value = selectedRows.value.map(item => item.opd_id)
    }
    isPrintResultLab.value = true
  }

  return {
    statusText,
    searchCustomerLoading,
    customerData,
    searchTextCustomer,
    customerList,
    dataBranchList,
    customer_id_pri,
    isPrintResultLab,
    sendPrintLab,
    sendPrint,
    shop_id_pri,
    status_id,
    opd_code,
    loading,
    dataTableList,
    footer,
    tableColumns,
    options,
    statusList,
    singleSelect,
    thisShop,
    idList,
    isPrintOpd,
    selectedRows,
    customFilter,
    printOpdData,
    printOpdImage,
  }
}
