<template>
  <v-dialog
    v-model="isPrintOpd"
    persistent
    max-width="300"
  >
    <v-card>
      <v-card-text>
        <p class="font-weight-bold text-lg">
          {{ $t("choose_the_paper_size") }}
        </p>
        <v-radio-group
          v-model="paperSize"
        >
          <v-radio
            label="A5"
            value="1"
          ></v-radio>
          <v-radio
            label="A4"
            value="2"
          ></v-radio>
        </v-radio-group>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="print"
        >
          {{ $t('confirm') }}
        </v-btn>
        <v-btn
          color="secondary"
          outlined
          @click="$emit('update:isPrintOpd',false)"
        >
          {{ $t('cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import router from '@/router'

export default {
  model: {
    prop: 'isPrintOpd',
    event: 'update:isPrintOpd',
  },
  props: {
    isPrintOpd: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const amount = ref(1)
    const labNumber = ref('')
    const paperSize = ref('1')
    const statusCheckLab = ref(false)

    const print = () => {
      let name = ''
      if (paperSize.value == '1') {
        name = 'printOpdData'
      } else {
        name = 'printOpdDataA4'
      }
      const routeData = router.resolve({
        name,
        query: {
          id: String(props.id),
        },
      })
      window.open(routeData.href, '_blank')
    }

    return {
      amount,
      labNumber,
      paperSize,
      statusCheckLab,
      print,
    }
  },
}
</script>
