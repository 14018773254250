import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const opdCardListByCustomer = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'opdcard/list',
      body,
    })
    .then(res => (res.data ? res.data : []))
    .catch(err => {
      console.error('get opd lsit err : ', err)

      return []
    })

  return response
}

export default { opdCardListByCustomer }
